var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex h-screen flex-wrap pt-10" },
    [
      _c(
        "div",
        { staticClass: "flex h-full w-1/2 flex-col overflow-auto" },
        [_c("WaitingList")],
        1
      ),
      _c("PanelArea", {
        staticClass: "flex-1 border-l",
        attrs: { "hide-booking-button": "", "show-assign-button": "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }