<template>
  <div class="flex h-screen flex-wrap pt-10">
    <div class="flex h-full w-1/2 flex-col overflow-auto">
      <WaitingList />
    </div>
    <PanelArea class="flex-1 border-l" hide-booking-button show-assign-button />
  </div>
</template>

<script>
import PanelArea from '@/components/interface/PanelArea.vue';
import WaitingList from '@/components/waiting-list/WaitingList.vue';

export default {
  components: {
    PanelArea,
    WaitingList,
  },
};
</script>
