var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "m-5 mt-16 h-full overflow-auto rounded-lg border-2 border-gray-300 bg-white",
    },
    [
      _vm._l(_vm.getAppointmentQueue, function (queueItem, index) {
        return _c(
          "button",
          {
            key: queueItem.id,
            staticClass:
              "flex w-full items-center justify-between border-b-2 text-left hover:bg-beigeLighter",
            class: [
              index === 0 ? "rounded-t-lg" : "",
              _vm.selectedAppointment === queueItem.appointment.id
                ? "bg-primary text-white hover:bg-primary"
                : "bg-white",
            ],
            on: {
              click: function ($event) {
                return _vm.handleAppointment(queueItem.appointment.id)
              },
            },
          },
          [
            _c("div", { staticClass: "p-3" }, [
              _c("h2", { staticClass: "py-1 font-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(queueItem.appointment.user[0].firstName) +
                    " " +
                    _vm._s(queueItem.appointment.user[0].lastName) +
                    " "
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "py-1",
                  class: _vm.selectedTextColor(queueItem.appointment.id, ""),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(queueItem.reason || "[placeholder reason]") +
                      " "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "p-3" }, [
              _c(
                "span",
                { class: _vm.selectedTextColor(queueItem.appointment.id) },
                [
                  _vm._v(
                    _vm._s(_vm.calculateWaitTime(queueItem.createdAt)) +
                      " minutes"
                  ),
                ]
              ),
            ]),
          ]
        )
      }),
      !_vm.getAppointmentQueueMeta.totalInQueue &&
      _vm.uiState === _vm.UI_STATES.IDLE
        ? _c("div", { staticClass: "flex justify-center" }, [
            _c("h2", { staticClass: "p-3 text-gray-500" }, [
              _vm._v("No one is in the queue right now"),
            ]),
          ])
        : !_vm.getAppointmentQueueMeta.totalInQueue &&
          _vm.uiState === _vm.UI_STATES.LOADING
        ? _c("div", { staticClass: "flex justify-center" }, [
            _c(
              "div",
              { staticClass: "h-16 w-16 text-gray-500" },
              [_c("base-spinner", { attrs: { loading: true, size: "mg" } })],
              1
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }