<template>
  <div
    class="m-5 mt-16 h-full overflow-auto rounded-lg border-2 border-gray-300 bg-white"
  >
    <button
      v-for="(queueItem, index) in getAppointmentQueue"
      :key="queueItem.id"
      class="flex w-full items-center justify-between border-b-2 text-left hover:bg-beigeLighter"
      :class="[
        index === 0 ? 'rounded-t-lg' : '',
        selectedAppointment === queueItem.appointment.id
          ? 'bg-primary text-white hover:bg-primary'
          : 'bg-white',
      ]"
      @click="handleAppointment(queueItem.appointment.id)"
    >
      <div class="p-3">
        <h2 class="py-1 font-bold">
          {{ queueItem.appointment.user[0].firstName }}
          {{ queueItem.appointment.user[0].lastName }}
        </h2>
        <p
          class="py-1"
          :class="selectedTextColor(queueItem.appointment.id, '')"
        >
          {{ queueItem.reason || '[placeholder reason]' }}
        </p>
      </div>
      <div class="p-3">
        <span :class="selectedTextColor(queueItem.appointment.id)"
          >{{ calculateWaitTime(queueItem.createdAt) }} minutes</span
        >
      </div>
    </button>
    <div
      v-if="!getAppointmentQueueMeta.totalInQueue && uiState === UI_STATES.IDLE"
      class="flex justify-center"
    >
      <h2 class="p-3 text-gray-500">No one is in the queue right now</h2>
    </div>
    <div
      v-else-if="
        !getAppointmentQueueMeta.totalInQueue && uiState === UI_STATES.LOADING
      "
      class="flex justify-center"
    >
      <div class="h-16 w-16 text-gray-500">
        <base-spinner :loading="true" size="mg" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { differenceInMinutes } from 'date-fns';

const UI_STATES = {
  LOADING: 'loading',
  IDLE: 'idle',
};
export default {
  data() {
    return {
      uiState: UI_STATES.LOADING,
      selectedAppointment: null,
      UI_STATES,
    };
  },
  computed: {
    ...mapGetters('waitingRoom', [
      'getAppointmentQueue',
      'getFirstAppointmentIdInQueue',
      'getAppointmentQueueMeta',
      'getEchoChangesInQueue',
    ]),
    queueChanges() {
      return this.getEchoChangesInQueue;
    },
  },

  watch: {
    queueChanges() {
      this.getAppointmentsInQueue();
    },
  },

  async beforeMount() {
    this.getAppointmentsInQueue();
  },

  methods: {
    ...mapActions('appointment', ['fetchAppointment']),
    ...mapActions('waitingRoom', ['fetchAppointmentQueue']),

    async getAppointmentsInQueue() {
      try {
        await this.fetchAppointmentQueue();
        if (this.getFirstAppointmentIdInQueue) {
          await this.handleAppointment(this.getFirstAppointmentIdInQueue);
        }
      } catch (error) {
        this.$notify({
          group: 'error',
          title: 'Could not fetch appointment queue',
          message: error.message,
        });
      }
      this.uiState = UI_STATES.IDLE;
    },

    async handleAppointment(appointmentId) {
      this.selectedAppointment = appointmentId;
      await this.fetchAppointment(appointmentId);
    },

    selectedTextColor(appointmentId, primaryColor = 'text-gray-500') {
      return this.selectedAppointment === appointmentId
        ? 'text-white'
        : primaryColor;
    },

    calculateWaitTime(createdAt) {
      return differenceInMinutes(new Date(), new Date(createdAt));
    },
  },
};
</script>
